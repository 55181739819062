import React, { useEffect } from 'react'
import Header from './components/Header'
import Body from './components/Body'
import About from './components/About'
import Products from './components/Products'
import Contact from './components/Contact'
import './App.css'

const App = () => {
  // const [ showNavBar, setShowNavBar ] = useState( true )
  
  

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.pageYOffset
  //     const viewportHeight = window.innerHeight
  //     const threshold = viewportHeight * 0.05 // 50vh

  //     if (scrollPosition >= threshold) {
  //       setShowNavBar(false)
  //     } else {
  //       setShowNavBar(true)
  //     }
  //   }

  //   window.addEventListener('scroll', handleScroll)

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  useEffect(() => {
    const handleScroll = () => {
      const fadeInElements = document.querySelectorAll('.fade-in')

      fadeInElements.forEach((element) => {
        const rect = element.getBoundingClientRect()
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0

        if (isVisible) {
          element.classList.add('visible')
        }
      })
    }

    const aboutPage = document.getElementById('first-in')
    if (aboutPage) {
      aboutPage.classList.add('visible')
    }

    window.addEventListener('scroll', handleScroll)

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [] )
  

  return (
    <div className='relative overflow-hidden'>
      <Header />
      <Body />
      <About />
      <Products />
      <Contact />
    </div>
  )
}

export default App
