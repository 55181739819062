import React from 'react'
// import { ABOUT_ITEMS } from '../constants'
// import AnimatedNumber from './utils/AnimatedNumber'

const About = () => {
  return (
    <div
      className='w-viewWidth py-8 bg-aboutTopBg flex flex-col-reverse midSm:flex-row items-center gap-6 midSm:pr-20'
      id='About us'
    >
      <div className='fade-in' id='first-in'>
        <div className='bg-aboutTopBg items-center md:items-start flex flex-col md:flex-row px-14 gap-10 pb-20 pt-6 '>
          <div>
            <p
              className='md:text-aboutText1 text-mobileAboutText1 text-center md:text-left '
              style={{ fontFamily: 'garamond-medium' }}
            >
              Who We Are
            </p>
            <p
              className='text-lg text-mobileAboutText2 md:text-aboutText2 md:leading-normal mt-2 text-center md:text-left'
              style={{ fontStyle: 'italic', fontFamily: 'garamond-medium' }}
            >
              Your premier destination for steel sourcing
            </p>
            <p
              className='lg:text-productDescription
              md:text-mediumProductDescription text-mobileProductDescription
              leading-10 text-sm md:leading-normal mt-8 text-center md:text-left'
              style={{ fontFamily: 'garamond-regular' }}
            >
              Welcome to Millbundle.com. We specialize in sourcing businesses
              with top-quality steel products from trusted suppliers worldwide.
              Our platform offers a comprehensive range of steel grades, shapes,
              and sizes, catering to diverse industries such as distributors,
              construction, manufacturing, and infrastructure development. With
              Millbundle.com, you can streamline your procurement process,
              saving time and resources while ensuring the highest standards of
              product quality. Whether you need bulk orders or specialized steel
              products, Millbundle.com is here to fulfill your steel sourcing
              needs with transparency, reliability, and competitive pricing.
              Partner with us today and experience a seamless steel sourcing
              experience that drives your business forward.
            </p>
          </div>
        </div>
      </div>
      {/* <img src='./aboutpeople1.png' alt='aboutpeople' className='w-peopleBg h-peopleBg rounded-sm'/> */}
      {/* <div className='bg-aboutBottomBg flex flex-col md:flex-row justify-center items-center md:h-96 md:gap-40 gap-14 py-8'>
        {ABOUT_ITEMS.map((item, i) => {
          return (
            <div key={i} className='flex flex-col items-center gap-1'>
              <img
                src={item.img}
                alt={`${item.name}{i}`}
                className='w-18 h-18'
              />
              <AnimatedNumber value={item.count} />
              <p className='tracking-widest text-aboutType'>{item.type}</p>
            </div>
          )
        })}
      </div> */}
    </div>
  )
}

export default About
