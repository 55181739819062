import React, { useEffect, useState } from 'react'
import { ALL_PRODUCTS, NAV_ITEMS } from '../constants'
import { GiHamburgerMenu } from 'react-icons/gi'
import { BiSolidChevronDown } from 'react-icons/bi'
import { useAppContext } from '../context'
import CloseIcon from './utils/CloseIcon'
const Header = () => {
  const { setSelectedMainTab } = useAppContext()

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      const sectionTop = section.offsetTop
      window.scrollTo({ top: sectionTop, behavior: 'smooth' })
    }
  }

  const [hoveredMenu, setHoveredMenu] = useState('')
  const [subMenuCoordinates, setSubMenuCoordinates] = useState({ x: 0, y: 0 })
  const [subMenuHover, setSubMenuHover] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [mobileSubmenuOpened, setMobileSubmenuOpened] = useState('')
  const [hoveredMenuWithOptions, setHoveredMenuWithOptions] = useState(null)
  const [mobileTopOffset, setMobileTopOffset] = useState(80)

  const handleMenuHover = (menuItem) => {
    setHoveredMenu(menuItem.name)
    setHoveredMenuWithOptions(menuItem)
    const element = document.querySelector(`[data-name="${menuItem.name}"]`)
    const bounding = element?.getBoundingClientRect()
    setSubMenuCoordinates({ x: bounding?.left, y: bounding?.bottom })
  }

  const handleMobileSubmenuOpen = (menuItem) => {
    if (mobileSubmenuOpened === menuItem) {
      setMobileSubmenuOpened('')
    } else {
      setMobileSubmenuOpened(menuItem)
    }
    setOpenMobileMenu(false)
  }

  const handleNavProductSelect = (option) => {
    const requiredProduct = ALL_PRODUCTS.find((item) => item.name === option)
    setSelectedMainTab(requiredProduct)
    scrollToSection('Products')
  }

  const handleDownload = (option) => {
    const link = document.createElement('a')
    link.href = `./${option}.pdf`
    link.download = `${option}.pdf`
    link.click()
  }

  const handleOptionClick = (option) => {
    if (hoveredMenuWithOptions?.name === 'Products') {
      handleNavProductSelect(option)
    } else if (hoveredMenuWithOptions?.name === 'Downloads') {
      handleDownload(option)
    }

    if (mobileSubmenuOpened === 'Products') {
      handleNavProductSelect(option)
      setOpenMobileMenu(false)
    } else if (mobileSubmenuOpened === 'Downloads') {
      handleDownload(option)
      setOpenMobileMenu(false)
    }
  }

  useEffect(() => {
    const updateElementPosition = () => {
      const headerHeight = 80 // Update this value with the actual height of your menubar
      const scrollY = window.scrollY || window.pageYOffset
      const newTopOffset = scrollY + headerHeight
      setMobileTopOffset(newTopOffset)
    }

    // Add scroll event listener to update the element position on scroll
    window.addEventListener('scroll', updateElementPosition)

    return () => {
      window.removeEventListener('scroll', updateElementPosition)
    }
  }, [mobileTopOffset])

  return (
    <div className='relative z-10'>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '80px',
          backgroundColor: '#11222ddd',
        }}
      />
      <div className='fixed flex items-center justify-between w-full md:px-28 px-3 mt-4 sm:mt-0'>
        <img
          src='./transparent.png'
          alt='transparent'
          className=' midSm:h-8 md:h-12 h-7 cursor-pointer'
          onClick={() => scrollToSection('main')}
        />
        <div className='hidden sm:flex gap-10 items-center'>
          {NAV_ITEMS.map((headerItem, i) => {
            return (
              <React.Fragment key={i}>
                <button
                  key={i}
                  data-name={headerItem.name}
                  className='text-md midSm:text-sm md:text-lg text-white hover:text-navTextHover h-20'
                  onMouseEnter={() => handleMenuHover(headerItem)}
                  onMouseLeave={() => setHoveredMenu('')}
                  onClick={() => scrollToSection(headerItem.name)}
                  style={{ fontFamily: 'garamond-regular' }}
                >
                  {headerItem.name}
                </button>
                {(hoveredMenu === headerItem.name || subMenuHover) &&
                  headerItem.options && (
                    <div
                      className={`absolute bg-menu shadow-lg`}
                      style={{
                        left: subMenuCoordinates?.x,
                        top: subMenuCoordinates?.y,
                      }}
                      onMouseEnter={() => setSubMenuHover(true)}
                      onMouseLeave={() => setSubMenuHover(false)}
                    >
                      {headerItem.options &&
                        hoveredMenuWithOptions?.options?.map((option, j) => (
                          <p
                            key={j}
                            className='text-xl hover:text-white text-white py-3 px-5 hover:bg-blue-900 cursor-pointer'
                            onClick={() => handleOptionClick(option)}
                            style={{ fontFamily: 'garamond-regular' }}
                          >
                            {option}
                          </p>
                        ))}
                    </div>
                  )}
              </React.Fragment>
            )
          })}
        </div>
          {openMobileMenu ? (
            <CloseIcon
              onClick={() => setOpenMobileMenu(false)}
              classN='sm:hidden cursor-pointer mr-2'
            />
          ) : (
            <GiHamburgerMenu
              className='sm:hidden w-6 h-6 cursor-pointer text-blue-100 mr-2'
              onClick={() => setOpenMobileMenu(true)}
            />
          )}
      </div>
      <div
        data-menu='mobile-menu-wrapper'
        className='absolute w-full md:hidden'
        style={{
          top: `${mobileTopOffset}px`,
          transform: openMobileMenu ? 'translateX(0%)' : 'translateX(-100%)',
          opacity: openMobileMenu ? 1 : 0,
          transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
        }}
      >
        {openMobileMenu && (
          <div className='flex flex-col border-t bg-white'>
            {NAV_ITEMS.map((headerItem, i) => {
              return (
                <div key={i}>
                  <div
                    className='flex justify-between px-6 py-3 border-b hover:bg-gray-100 cursor-pointer'
                    onClick={() => handleMobileSubmenuOpen(headerItem.name)}
                  >
                    <p
                      className='text-sm'
                      onClick={() => scrollToSection(headerItem.name)}
                      style={{ fontFamily: 'garamond-regular' }}
                    >
                      {headerItem.name}
                    </p>
                    {headerItem.options.length > 0 && <BiSolidChevronDown />}
                  </div>
                  {headerItem?.options &&
                    headerItem.name === mobileSubmenuOpened && (
                      <div className='border-b'>
                        {headerItem.options.map((option, j) => {
                          return (
                            <p
                              key={j}
                              className='text-xs px-8 py-3 hover:bg-gray-100 cursor-pointer text-gray-600'
                              onClick={() => handleOptionClick(option)}
                              style={{ fontFamily: 'garamond-regular' }}
                            >
                              {option}
                            </p>
                          )
                        })}
                      </div>
                    )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
