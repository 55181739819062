import React, { useEffect, useRef } from 'react'

const Body = () => {
  const videoRef = useRef( null )
   useEffect(() => {
     const video = videoRef.current

     const playVideo = () => {
       video.play().catch((error) => {
         console.error('Video autoplay failed:', error)
       })
     }

     const handleUserInteraction = () => {
       playVideo()
       video.addEventListener('ended', handleVideoEnd, false)
     }

     video.addEventListener('canplay', handleUserInteraction, false)

     return () => {
       video.removeEventListener('canplay', handleUserInteraction, false)
       video.removeEventListener('ended', handleVideoEnd, false)
     }
   }, [])

   const handleVideoEnd = () => {
     const video = videoRef.current
     video.currentTime = 0
     video.play()
   }
  return (
    <div id='main'>
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        className='object-contain mt-20'
        style={{objectFit:'contain'}}
      >
        <source src='./web_video.mp4' type='video/mp4' />
      </video>
    </div>
  )
}

export default Body
