import { createContext, useContext, useState } from "react";
import { ALL_PRODUCTS } from "./constants";

const AppContext = createContext( null )

export const ContextProvider = ( { children } ) => {
  const [selectedMainTab, setSelectedMainTab] = useState(ALL_PRODUCTS[0])
  return (
    <AppContext.Provider value={{selectedMainTab, setSelectedMainTab}}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)