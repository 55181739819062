export const NAV_ITEMS = [
  {
    name: 'About us',
    options: [],
  },
  {
    name: 'Products',
    options: [
      'Standard Stainless Tubes, Pipes and Bars',
      'Special Stainless Bars',
      'Stainless Wires',
      'Carbon Tubes, Pipes and Bars',
      'Copper Products'
    ],
  },
  {
    name: 'Downloads',
    options: ['T&C of Sale', 'T&C of Purchase'],
  },
  {
    name: 'Contact us',
    options: [],
  },
]

export const ABOUT_ITEMS = [
  {
    img: './icon5.png',
    count: '16',
    type: 'SPECIALISTS',
  },
  {
    img: './icon6.png',
    count: '1256',
    type: 'HAPPY CLIENTS',
  },
  {
    img: './icon7.png',
    count: '27',
    type: 'SUCCESSFUL CASES',
  },
  {
    img: './icon8.png',
    count: '14',
    type: 'SPECIALISTS',
  },
]

export const PRODUCTS_IMG = [
  './products1.png',
  './products2.png',
  './products3.png',
  './products4.png',
]

export const PRODUCTS_ITEMS = {
  'Coarse Wire': {
    img: './productDetailed.png',
    text: 'We offer Stainless Steel Fine Wires ranging from 0.10 mm to 0.90mm (0.004" - 0.035"). The main applications are Braiding, Knitting & Weaving. With a combination of customized chemistry, drawing process and die selection, we offer an excellent durability and finish to the material while meeting the mechanical property requirements of the application. Fine wires are generally supplied on plastic spool as per the DIN Specification (DIN 125/160/200/250/355 etc.). Customized packaging options are available on demand.',
  },
  'Fine Wire': {
    img: '',
    text: 'We offer Stainless Steel Fine Wires ranging from 0.10 mm to 0.90mm (0.004" - 0.035"). The main applications are Braiding, Knitting & Weaving. With a combination of customized chemistry, drawing process and die selection, we offer an excellent durability and finish to the material while meeting the mechanical property requirements of the application. Fine wires are generally supplied on plastic spool as per the DIN Specification (DIN 125/160/200/250/355 etc.). Customized packaging options are available on demand.',
  },
  'Wire Rods': {
    img: '',
    text: 'We offer Stainless Steel Fine Wires ranging from 0.10 mm to 0.90mm (0.004" - 0.035"). The main applications are Braiding, Knitting & Weaving. With a combination of customized chemistry, drawing process and die selection, we offer an excellent durability and finish to the material while meeting the mechanical property requirements of the application. Fine wires are generally supplied on plastic spool as per the DIN Specification (DIN 125/160/200/250/355 etc.). Customized packaging options are available on demand.',
  },
}

export const ALL_PRODUCTS = [
  // PRODUCT - 1 (Standard Stainless Tubes, Pipes and Bars)
  {
    name: 'Standard Stainless Tubes, Pipes and Bars',
    iconImg: './stainlesstubes.jpg',
    img: './stainlesstubes.jpg',
    items: [
      {
        name: 'Stainless Steel Tubing',
        img: './stainlesstubes.jpg',
        detail: {
          grades: "304, 316, 321, 409, 2205 Duplex and customized chemistry per customer needs.",
          sizeRanges: [
            '<b>Round Tubes</b>: 1/8" to 36" or more.',
            '<b>Square Tubes</b>: Sizes ranging from 1/2" x 1/2" to 12" x 12" or more.',
            '<b>Rectangular Tubes</b>: Dimensions from 1" x 1/2" to 24" x 12" or more.'
          ],
          lengths: "Custom lengths available",
          specifications: [
            "ASTM A269",
            "ASTM A554",
            "ASTM A213",
            "And other specs as per customer needs.",
          ]
        },
        description: 'Our extensive selection comprises a wide array of stainless steel grades, providing diverse options for your specific requirements. Choose from our range, which includes 304/304L, 316/316L, 321/321H, 310/310S, 409, 410, 17-4 PH, and Duplex 2205, to meet various industrial and application needs.',
      },
      {
        name: 'Stainless Steel Piping',
        img: './stainlesstubes.jpg',
        detail: {
          grades: "304, 316, 317, 347, 904L",
          sizeRanges: [
            'Nominal Pipe Size (NPS): 1/8" to 24"'
          ],
          schedule: "5S to XXH",
          lengths: "Single Random, Double Random, Custom lengths",
          specifications: [
            "ASTM A312, ASTM A790 and other specs and sizes as per customer needs.",
          ]
        },
        description:
          'We offer a diverse selection of tubes to suit your needs. Our round tubes come in various diameters, ranging from 1/8" to 36" or more. For square tubes, we provide sizes from 1/2" x 1/2" to 12" x 12" or more. Rectangular tubes are available in dimensions from 1" x 1/2" to 24" x 12" or more. Oval tubes can be obtained in various sizes upon request. Additionally, we offer seamless tubes with different sizes and wall thicknesses available upon request, as well as welded tubes in various sizes and wall thicknesses upon request.',
      },
      {
        name: 'Stainless Steel Bars',
        img: './stainlesstubes.jpg',
        detail: {
          grades: "303, 304, 316, 410, 417, 17-4 PH and special grades as per customer needs",
          sizeRanges: [
            'Diameter: 1/4" to 12"'
          ],
          lengths: "Random, Custom lengths available",
          specifications: [
            "ASTM A276, ASTM A484 and other specs as per customer needs.",
            "All Round Bars can be offer in Specs of ASTM, SAE-AMS, QQS, ASME, NACE, API, MIL EN, DIN, JIS, BS and GOST.",
            "All Hex and Squares are offered in ASTM A484, ISO h11 and k12. Supplied in cold drawn and polished condition, the products comply with all major industrial standards.",
          ]
        },
        description:
          'We offer a diverse selection of pipes to meet a wide range of needs. Our Nominal Pipe Sizes (NPS) span from 1/8" to 36" or beyond, with common schedules including 5, 10, 40, 80, 160, and XXH (extra heavy). You can choose from seamless and welded pipes with various sizes and wall thicknesses. For specific shapes, we provide round pipes with diameters ranging from 1/8" to 36" or more, square pipes from 1/2" x 1/2" to 12" x 12" or more, and rectangular pipes with dimensions from 1" x 1/2" to 24" x 12" or more. Whatever your requirements, we have the perfect pipes to suit your project.',
      },
    ],
  },
  // PRODUCT - 2 (Special Stainless Bars)
  {
    name: 'Special Stainless Bars',
    iconImg: './stainlessbars1.png',
    img: './stainlessbars1.png',
    items: [
      {
        name: 'Shaft Quality Bars',
        img: './stainlessbars1.png',
        detail: {
          grades: "AISI 303, 304, 304L, 321, 316, 316L, 316Ti, 310, 403, 409, 410, 416, 420, 430, 430F, 430LNB, 431, 630 & 2205",
          usage: "Pump shaft, pneumatic and piston shafts, boats shafts with very little machining due to its close tolerance, finish, precision straightness, no-ovality and internal soundness (100% crack- free) properties.",
          industries: "Manufacturing assembly lines as Pneumatic Cylinders, Propeller Shafts, Boat Shafts & Pump Shafts."
        },
        description:
          'We offer stainless steel bars in austenitic, ferritic, martensitic, and precipitation hardening stainless varieties, ranging from 1.60 mm to 155 mm (1/16” to 6”) in size. Our in-house cold finishing and heat treatment processes grant us full control over product characteristics, allowing tailored solutions for diverse industries. We offer bar products meeting ASTM, SAE-AMS, QQS, ASME, NACE, API, MIL EN, DIN, JIS, BS, and GOST specifications, as well as customer-specific requirements. With customized chemistry and precise diameter tolerances, we cater to Aerospace, Automotive, Energy, Power Distribution, Oil & Gas, and other sectors. Our round, hexagon, and square bars adhere to major industrial standards.',
      },
      {
        name: 'FITMACH and HT AISI 630 Bars',
        img: './stainlessbars1.png',
        detail: {
          nameFit: 'FITMACH Bars',
          grades: "AISI 303(W.Nr 1.4305), AISI 416(W.Nr 1.4005) and AISI 430F (W.Nr. 1.4105/1.4104)",
          usageFit: "Improved machinability version of the free machining grades. With tailored chemistry and calcium treatment, the products offer high machinability without compromising corrosion resistance and internal soundness. Suitable for screw machining and CNC machining,",
          industries: "Manufacturing assembly lines as Pneumatic Cylinders, Propeller Shafts, Boat Shafts & Pump Shafts.",
          nameAisi: "AISI 630 Bars",
          usageAisi: "Achieve mechanicals, impact strength and other characteristics of manufacturer’s needs.",
          specialty: ["Uniformly Heat Treated for precipitation of hardened stainless."]
        },
        description:
          'Our VSQ bars have exceptional shafting suitability, widely used in pump shafts, pneumatic and piston shafts, and boat shafts, with minimal machining required due to precise tolerance, straightness, finish, and crack-free properties. Produced in various grades, our Precision Ground & Mirror Polished VSQ bars find applications in manufacturing Pneumatic Cylinders, Propeller Shafts, Boat Shafts & Pump Shafts. FITMACH bars, offering high machinability without compromising corrosion resistance and internal soundness, are suitable for high-speed screw and CNC machining and currently available in AISI 303(W.Nr 1.4305), AISI 416(W.Nr 1.4005), and AISI 430F (W.Nr. 1.4105/1.4104) grades.',
      },
      {
        name: 'Solenoid Bars',
        img: './stainlessbars1.png',
        detail: {
          usage: "Solenoid quality bars/solenoid valves are electro-mechanical devices used where the flow of a fluid such as air, gas, water, oil, or steam must be controlled automatically.",
          specialty: [
            "More efficient than manual valves.",
            "Properties of stainless steel, such as non-corrosive nature, 100 percent recyclability, heat resistance, etc. make it the most preferred alloy.",
            "Stainless steel solenoid valves are ideal for all kinds of installations, especially where there is exposure to harsh conditions, such as direct sunlight, extreme heat, sub-zero temperatures, moisture, chemicals, dirt, and dust.",
            "Perfect magnetic properties such as the Permeability and Residual Induction for the Solenoid application."
          ]
        },
        description:
          'TRU-TUF bars are customized AISI 630 bars from our manufacturing partner. Uniform heat treatment in our computer-controlled furnaces ensures the desired mechanicals, impact strength, and characteristics. We offer TRU-TUF bars in all ageing conditions. Stainless steel solenoid valves are highly efficient electro-mechanical devices used for automatic fluid control in various industries. The non-corrosive nature, recyclability, and heat resistance of stainless steel make it ideal for installations exposed to harsh conditions. Specially designed stainless steel by our partners possesses the perfect magnetic properties, making it suitable for solenoid applications.',
      },
    ],
  },
  // PRODUCT - 3 (Stainless Wires)
  {
    name: 'Stainless Wires',
    img: './weldingwires1.jpg',
    iconImg: './weldingwires1.jpg',
    items: [
      {
        name: 'Stainless Standard Wires',
        img: './weldingwires1.jpg',
        detail: {
          items: [
            {
              name: "Coarse Wire",
              shortName: "Coarse Wire",
              size: '0.028" – 0.870"',
              grades: "Produced in more than 50 grades we cover all possible stainless steel grades as well as high nickel and duplex, super duplex grades.",
              applications: "Conveyor Belts, Fencing, Filters, Roofing Hooks, etc.",
              note: "Customized chemistry which can perfectly suit your applications."
            },
            {
              name: "Fine Wire",
              shortName: "Fine Wire",
              size: '0.004" - 0.035"',
              grades: "Fine wires are generally supplied on plastic spool as per the DIN Specification (DIN 125/160/200/250/355 etc.). Customized packaging options are available on demand.",
              applications: "Braiding, Knitting & Weaving.",
              note: "Customized chemistry which can perfectly suit your applications."
            },
            {
              name: "Wire Rods",
              shortName: "Wire Rods",
              size: '0.217" to 1.00" Hot Rolled, Annealed and Pickled condition with option of soap coating.',
              applications: "Wire drawing, Exhaust hangers, Lightning conductors etc.",
              note: "Customized chemistry to best suit your application."
            }
          ]
        },
        description:
          'Our manufacturing units are well equipped with the latest technology that meets international manufacturing standards. We offer Stainless Steel Coarse Wires in a wide range of sizes, from 0.70 mm to 22 mm (0.028" – 0.870"). Produced in more than 50 grades we cover all possible stainless steel grades as well as high nickel and duplex, super duplex grades. These wires are exported to more than 60 countries around the world for various applications like Conveyor Belts, Fencing, Filters, Roofing Hooks, etc. All our products have an excellent matte finish or a bright finish and meet the international grade and standard. We also provide customized chemistries which can perfectly suit your applications.',
      },
      {
        name: 'Stainless Welding Wires',
        img: './weldingwires1.jpg',
        detail: {
          items: [
            {
              name: "TIG (Tungsten Inert Gas) Wires",
              shortName: "TIG Wires",
              size: "0.80 mm to 6.00 mm (0.030” to 0.236”)",
              specifications: [
                "Austenitic, duplex, martensitic, ferritic and precipitation hardening stainless steel according to AWS SFA 5.9 standard."
              ],
              note: "Customized Packaging."
            },
            {
              name: "MIG (Metal Inert Gas ) Wires/Gas Metal Arc Welding (GMAW)",
              shortName: "MIG / GMAW Wires",
              applications: "Robotics, Shop floor jobs where continuous weld through machines are preferred.",
              specifications: [
                "Stringent welding qualification by the customer or product approval by independent agencies. Produced to AWS SFA 5.9 standards. Produced in spool, drum or reel as the application warrants."
              ],
              note: "Customized packaging options are available for distributors."
            },
            {
              name: "Core Wires",
              shortName: "Core Wires",
              size: "Lengths customized as per customer request",
              applications: "Stainless Steel Core Wires for electrodes manufacturing.",
              specifications: [
                "Customized chemistry for core wires"
              ],
            },
            {
              name: "WUB ARC Wires",
              shortName: "WUB ARC Wires",
              applications: " Preferred mode of welding for Long Straight Seams, Shipbuilding Industries, Oil & Gas Refineries, Fabrication of LPG Cylinders, Bridge Girders & Rotated Pipes or Vessels. We offer excellent Sub Arc Weld Wires for the enhanced performance of continuous arc welding.",
              specifications: [
                "Customized as per customer needs. Compliance with the specifications of the American Welding Society (AWS)."
              ],
            },
          ]
        },
        description:
          'We offer Stainless Steel Fine Wires ranging from 0.10 mm to 0.90 mm (0.004″ - 0.035″). The main applications are Braiding, Knitting & Weaving. With a combination of customized chemistry, drawing process and die selection, we offer an excellent durability and finish to the material while meeting the mechanical property requirements of the application. Fine wires are generally supplied on plastic spool as per the DIN Specification (DIN 125/160/200/250/355 etc.). Customized packaging options are available on demand.',
      },
    ],
  },
  // PRODUCT - 4 (Carbon Tubes, Pipes and Bars)
  {
    name: 'Carbon Tubes, Pipes and Bars',
    iconImg: './carbonTubes.jpeg',
    img: './carbonTubes.jpeg',
    items: [
      {
        name: 'Carbon Steel Tubing',
        img: './carbonTubes.jpeg',
        detail: {
          grades: "A513 (mechanical tubing), A500 (structural tubing), A179 (boiler tubing), A106 (seamless tubing), A53 (welded tubing).",
          sizeRanges: ['Outer Diameter - Typically ranges from 1/8" to 24" and more'],
          wallThickness: "Varied based on application, ranging from SCH 10 to SCH 160",
          specifications: [
            "ASTM A513",
            "ASTM A500",
            "ASTM A179",
            "ASTM A106",
            "ASTM A53",
          ]
        },
        description:
          'Our Gas Tungsten Arc Welding (GTAW/TIG) wires offer versatile applications and high-quality welds for stainless steel. Sizes range from 0.80 mm to 6.00 mm, meeting AWS SFA 5.9 standard. Gas Metal Arc Welding (GMAW/MIG) is ideal for robotic and continuous weld jobs. Our superior MIG welding wires adhere to AWS SFA 5.9 standards, with various packaging options, including customization for distributors, available worldwide.',
      },
      {
        name: 'Carbon Steel Piping',
        img: './carbonSteelPiping.jpeg',
        detail: {
          grades: "A106 (seamless), A53 (welded), API 5L (line pipe), A333 (low-temperature service), J55 Upgradeable Green Pipe, SA179, SA192, SA210, SA213 etc.",
          sizeRanges: ['Nominal Pipe Size (NPS): 1/8" to 36"'],
          schedule: "SCH 10 to SCH 160",
          specifications: [
            "ASTM A106",
            "ASTM A53",
            "API 5L",
            "ASTM A333",
          ]
        },
        description:
          'We offer Stainless Steel Core Wires for electrodes manufacturing. We supply to numerous electrode manufacturers around the globe. Our core wires are produced in various grades and supplied in cut lengths or coil form as per our customer’s needs. Customized chemistry for core wires are provided which helps the users with flexibility for flux.',
      },
      {
        name: 'Carbon Steel Bars',
        img: './carbonSteelBars.jpeg',
        detail: {
          grades: "1018, 1045, 4140, 4340, 12L14 (free machining)",
          sizeRanges: [
            '<b>Diameter</b>: Typically ranges from 1/4" to 24"',
            '<b>Length</b>: Standard lengths or custom cut lengths'
          ],
          specifications: [
            "ASTM A108 - Standard Specification for Steel Bar, Carbon and Alloy, Cold-Finished",
            "ASTM A29 - Standard Specification for General Requirements for Steel Bars, Carbon and Alloy, Hot-Wrought"
          ]
        },
        description:
          'Submerged arc welding is the most preferred mode of welding for Long Straight Seams, Shipbuilding Industries, Oil & Gas Refineries, Fabrication of LPG Cylinders, Bridge Girders & Rotated Pipes or Vessels. We offer excellent Sub Arc Weld Wires for the enhanced performance of continuous arc welding. Precision diameter tolerance of our wires makes the job easy for an arc welder. Our SS wires offer superior weld quality and fully comply with the specifications of the American Welding Society (AWS).',
      },
    ],
  },
  // PRODUCT - 5 (Copper Products)
  {
    name: 'Copper Products',
    iconImg: './copperProducts.jpeg',
    img: './copperProducts.jpeg',
    items: [
      {
        name: 'Copper Pipes',
        img: './copperPipes.jpeg',
        detail: {
          types: "K, L and M Type Tubing.",
          sizeRanges: ["½” (5/8” Nominal Size) to 3” and larger as per customer application"],
          specifications: [
            "ASTM B88",
            "ASTM B280"
          ]
        },
        description:
          'Our Gas Tungsten Arc Welding (GTAW/TIG) wires offer versatile applications and high-quality welds for stainless steel. Sizes range from 0.80 mm to 6.00 mm, meeting AWS SFA 5.9 standard. Gas Metal Arc Welding (GMAW/MIG) is ideal for robotic and continuous weld jobs. Our superior MIG welding wires adhere to AWS SFA 5.9 standards, with various packaging options, including customization for distributors, available worldwide.',
      },
      {
        name: 'Copper Tubes',
        img: './copperTubes.jpeg',
        detail: {
          types: "K, L and M Type Tubing.",
          sizeRanges: ['½” to 1” OD range + customized customer request sizes.'],
          temper: "Annealed, Half Hard and Hard.",
          wallThickness: "Industry standard produced per customer application.",
          specifications: [
            "ASTM B88",
            "ASTM B280",
            "ASTM B819"
          ],
        },
        description:
          'Our Gas Tungsten Arc Welding (GTAW/TIG) wires offer versatile applications and high-quality welds for stainless steel. Sizes range from 0.80 mm to 6.00 mm, meeting AWS SFA 5.9 standard. Gas Metal Arc Welding (GMAW/MIG) is ideal for robotic and continuous weld jobs. Our superior MIG welding wires adhere to AWS SFA 5.9 standards, with various packaging options, including customization for distributors, available worldwide.',
      },
      {
        name: 'Copper Wire Products',
        img: './copperWireProducts.jpeg',
        detail: {
          name: "Copper wire ropes",
          specifications: [
            "Copper wire ropes",
            "Tinned- Bare, Flat Braided and Round Flexible Copper Wire",
            "Nickel Plated Copper Wire",
            "Solid Bare Copper Wire",
            "Tinsel Tin, Silver and Cadmium Wires",
            "Round and bare Silver-Plated Copper Wires",
            "Copper Clad Aluminum Wire",
            "Copper Alloy Wire",
            "Aluminum Magnet, Grounding and Winding Wires",
            "Super Enamelled Aluminum Winding Wire",
            "H Class Super Enamelled Coated Copper Wire",
            "Enamelled Aluminum winding Round Wire",
            "Submersible Regular, Motor and Pump Winding Wire",
            "Copper Tape and Strips",
          ],
          note: "<br />Grades Thickness ranging from <br /> <b>Gauges</b>: Per AWG standard <br /> <b>Specs</b>: NEC and IEC Standards"

        },
        description:
          'We offer Stainless Steel Core Wires for electrodes manufacturing. We supply to numerous electrode manufacturers around the globe. Our core wires are produced in various grades and supplied in cut lengths or coil form as per our customer’s needs. Customized chemistry for core wires are provided which helps the users with flexibility for flux.',
      },
    ],
  },
]
