import React, { useEffect, useState } from 'react'
import { ALL_PRODUCTS } from '../constants'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import { useAppContext } from '../context'
import { BsArrowRight } from 'react-icons/bs'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import parse from 'html-react-parser';

const Products = () => {
  const { selectedMainTab, setSelectedMainTab } = useAppContext()
  const [selectedProductTab, setSelectedProductTab] = useState(null)
  const [selectedItemTab, setSelectedItemTab] = useState(null)
  const [selectedProductIndices, setSelectedProductIndices] = useState([
    0, 1, 2,
  ])

  const handleMoveTabs = (type) => {
    const totalLength = selectedMainTab?.items?.length
    let newArray
    switch (type) {
      case 'left':
        if (selectedProductIndices[0] !== 0) {
          newArray = selectedProductIndices.map((tab) => tab - 1)
        } else {
          newArray = selectedProductIndices.map((tab) => tab)
        }
        setSelectedProductIndices([...newArray])
        break
      case 'right':
        if (selectedProductIndices[2] !== totalLength - 1) {
          newArray = selectedProductIndices.map((tab) => tab + 1)
        } else {
          newArray = selectedProductIndices.map((tab) => tab)
        }
        setSelectedProductIndices([...newArray])
        break
      default:
        return
    }
  }

  useEffect(() => {
    if (selectedMainTab) {
      if (selectedMainTab.items.length < 4) {
        setSelectedProductIndices([0, 1, 2])
      }
      setSelectedProductTab(selectedMainTab?.items[0])
    }
    if (selectedMainTab?.name === "Stainless Wires" || selectedMainTab?.name === "Copper Products") { // || selectedMainTab?.name === "Stainless Welding Wires"
      setSelectedItemTab(selectedMainTab?.items[0])
    }
  }, [selectedMainTab])

  useEffect(() => {
    if (selectedItemTab) {
      if (selectedProductTab?.name === "Stainless Standard Wires")
        setSelectedItemTab(selectedMainTab?.items[0]?.detail?.items[0])
      else if (selectedProductTab?.name === "Stainless Welding Wires")
        setSelectedItemTab(selectedMainTab?.items[1]?.detail?.items[0])
    }
  }, [selectedProductTab])

  return (
    <div
      id='Products'
      className='fade-in w-viewWidth xl:min-h-productsHeight sm:px-44 sm:pt-32 pt-12 pb-8 xl:pb-12 flex flex-col items-center justify-center'
      style={{
        backgroundImage: 'url("./productsBg.jpg")',
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#658CBB88',
          zIndex: 1,
        }}
      />
      <div
        className='w-full'
        style={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        <div className='flex flex-row flex-wrap items-center justify-center md:flex-row midSm:flex-wrap gap-6 md:justify-center md:gap-10'>
          {ALL_PRODUCTS.map((product, i) => {
            return (
              <div key={i}
                className={`cursor-pointer flex flex-col justify-center items-center bg-productsSelectorBg w-52 h-52 text-center gap-2 ${selectedMainTab.name === product.name
                  ? 'p-6 border-yellow-500 border-4 font-semibold lg:p-8'
                  : 'p-10'
                  } rounded-lg`}
                onClick={() => setSelectedMainTab(product)}
              >
                <img
                  src={product.iconImg}
                  alt={product.name}
                  className={`grayscale sm:w-14 sm:h-14 w-8 h-8 rounded-md ${selectedMainTab.name === product.name && 'scale-110'}`}
                />
                <p
                  className='lg:text-tabText text-sm md:text-md'
                  style={{ fontFamily: 'garamond-regular' }}
                >
                  {product.name}
                </p>
              </div>
            )
          })}
        </div>
        <div className='bg-aboutTopBg w-full mt-16 pt-4'>
          <div className='justify-center items-center flex gap-2'>
            {selectedMainTab?.items?.length > 3 && (
              <BsChevronCompactLeft
                className='md:w-20 md:h-20 w-44 h-48 cursor-pointer'
                onClick={() => handleMoveTabs('left')}
              />
            )}
            <div
              className='items-center justify-center flex-col md:flex-row md:justify-center flex gap-2 overflow-hidden mx-10'
              style={{ width: '1400px' }}
            >
              {selectedMainTab?.items
                .slice(selectedProductIndices[0], selectedProductIndices[2] + 1)
                .map((productTabs, i) => {
                  return (
                    <div
                      className={`bg-productTabsBg rounded-md text-white text-center lg:text-tabText cursor-pointer w-subTabWidthNormal py-6`}
                      key={i}
                      style={{
                        minWidth: '320px',
                        color: `${selectedProductTab?.name === productTabs?.name
                          ? '#f0c519'
                          : ''
                          }`,
                      }}
                      onClick={() => {
                        setSelectedProductTab(productTabs)
                        setSelectedItemTab(productTabs?.detail?.items?.[0])
                      }}
                    >
                      <p
                        data-id={productTabs.name}
                        style={{
                          fontFamily: `${selectedProductTab?.name === productTabs?.name
                            ? 'garamond-semibold'
                            : 'garamond-regular'
                            }`,
                        }}
                      >
                        {productTabs?.name}
                      </p>
                    </div>
                  )
                })}
            </div>
            {selectedMainTab?.items?.length > 3 && (
              <BsChevronCompactRight
                className='md:w-20 md:h-20 w-44 h-48 cursor-pointer'
                onClick={() => handleMoveTabs('right')}
              />
            )}
          </div>
          <div className='h-[500px] flex flex-col md:flex-row text-start md:text-left gap-6 md:px-24 px-4 items-center pt-10 md:pt-0'>
            <img
              src={selectedProductTab?.img}
              alt={selectedProductTab}
              className='lg:h-singleProduct object-contain lg:w-singleProduct rounded-lg h-mobileSingleProduct md:h-mediumSingleProduct'
            />
            <div style={{ fontFamily: 'garamond-regular' }} className='w-full overflow-auto px-5 h-[300px] lg:text-productDescription md:text-mediumProductDescription md:leading-10 text-sm text-mobileProductDescription md:pb-0 pb-5'>
              {selectedProductTab?.detail?.nameFit && <div className='md:mb-5 font-semibold text-black'>{selectedProductTab.detail.nameFit}</div>}
              {selectedProductTab?.detail?.grades && <div className='md:mb-5'><span className='font-semibold underline '>Grades</span>: {selectedProductTab.detail.grades}</div>}
              {selectedProductTab?.detail?.usageFit && <div className='md:my-5'><span className='font-semibold underline '>Usage</span>: {selectedProductTab.detail.usageFit}</div>}
              {selectedProductTab?.detail?.usage && <div className='md:mb-5'><span className='font-semibold underline '>Usage</span>: {selectedProductTab.detail.usage}</div>}
              {selectedProductTab?.detail?.industries && <div className='md:my-5'><span className='font-semibold underline '>Industries</span>: {selectedProductTab.detail.industries}</div>}
              {selectedProductTab?.detail?.nameAisi && <div className='md:my-5 font-semibold text-black'>{selectedProductTab.detail.nameAisi}</div>}
              {selectedProductTab?.detail?.usageAisi && <div className=''><span className='font-semibold underline '>Usage</span>: {selectedProductTab.detail.usageAisi}</div>}
              {selectedProductTab?.detail?.specialty && <div className='md:my-5'><span className='font-semibold underline '>Specialty</span>: {selectedProductTab.detail.specialty.length === 1 ? selectedProductTab.detail.specialty : selectedProductTab.detail.specialty.map((s, k) => <div className='mt-2' key={k}>{k + 1}. {parse(s)}</div>)}</div>}
              {selectedProductTab?.detail?.types && <div className='md:mb-5'><span className='font-semibold underline '>Types</span>: {selectedProductTab.detail.types}</div>}
              {selectedProductTab?.detail?.sizeRanges && <div className='md:mb-5'><span className='font-semibold underline '>Size Ranges</span>: {selectedProductTab.detail.sizeRanges.length === 1 ? selectedProductTab.detail.sizeRanges : selectedProductTab.detail.sizeRanges.map((sr, k) => <div className=' md:mt-2' key={k}>{k + 1}. {parse(sr)}</div>)}</div>}
              {selectedProductTab?.detail?.lengths && <div className='md:my-5'><span className='font-semibold underline '>Lengths</span>: {selectedProductTab.detail.lengths}</div>}
              {selectedProductTab?.detail?.schedule && <div className='md:my-5'><span className='font-semibold underline '>Schedule</span>: {selectedProductTab.detail.schedule}</div>}
              {selectedProductTab?.detail?.wallThickness && <div className='md:mb-5'><span className='font-semibold underline '>Wall Thickness</span>: {selectedProductTab.detail.wallThickness}</div>}
              {selectedProductTab?.detail?.temper && <div className='md:mb-5'><span className='font-semibold underline '>Temper</span>: {selectedProductTab.detail.temper}</div>}
              {selectedProductTab?.detail?.note && <div className='md:my-5'><span className='font-semibold underline '>Note</span>: {parse(selectedProductTab.detail.note)}</div>}
              {selectedProductTab?.detail?.specifications && <div className='md:my-5'><span className='font-semibold underline leading-none'>Specification</span>: {selectedProductTab.detail.specifications.length === 1 ? selectedProductTab.detail.specifications : selectedProductTab.detail.specifications.map((sr, k) => <div className=' md:mt-2' key={k}>{k + 1}. {parse(sr)}</div>)}</div>}
              {(selectedProductTab?.name === "Stainless Standard Wires" || selectedProductTab?.name === "Stainless Welding Wires" || selectedProductTab?.name === "Copper Pipe and Tubes" || selectedProductTab?.name === "Copper Wire Products") &&
                <div>
                  {selectedItemTab?.name && <div className='md:my-5'><span className='font-semibold underline '>Name</span>: {selectedItemTab?.name}</div>}
                  {selectedItemTab?.types && <div className='md:my-5'><span className='font-semibold underline '>Type</span>: {selectedItemTab?.types}</div>}
                  {selectedItemTab?.size && <div className='md:my-5'><span className='font-semibold underline '>Size</span>: {selectedItemTab?.size}</div>}
                  {selectedItemTab?.temper && <div className='md:my-5'><span className='font-semibold underline '>Temper</span>: {selectedItemTab?.temper}</div>}
                  {selectedItemTab?.wallThickness && <div className='md:my-5'><span className='font-semibold underline '>Wall Thickness</span>: {selectedItemTab?.wallThickness}</div>}
                  {selectedItemTab?.grades && <div className='md:my-5'><span className='font-semibold underline '>Grades</span>: {selectedItemTab?.grades}</div>}
                  {selectedItemTab?.applications && <div className='md:my-5'><span className='font-semibold underline '>Application</span>: {selectedItemTab?.applications}</div>}
                  {selectedItemTab?.specifications && <div className='md:my-5'><span className='font-semibold underline '>Specification</span>: {selectedItemTab.specifications.length === 1 ? selectedItemTab.specifications : selectedItemTab.specifications.map((sr, k) => <div className='md:mt-2' key={k}>{k + 1}. {parse(sr)}</div>)}</div>}
                  {selectedItemTab?.note && <div className='md:my-5'><span className='font-semibold underline  text-black'>Note</span>: {parse(selectedItemTab?.note)}</div>}
                </div>
              }
            </div>
          </div>
          <div className={`${selectedProductTab?.detail?.items?.length > 0 ? "flex justify-center flex-wrap w-full bg-aboutTopBg lg:text-productDescription md:text-mediumProductDescription text-sm md:absolute md:bottom-0 gap-10 pb-10" : "hidden"}`}>
            {selectedProductTab?.detail?.items?.map((itemTabs, i) => {
              return (
                <div key={i} onClick={() => setSelectedItemTab(itemTabs)} className={`cursor-pointer ${selectedItemTab?.name === itemTabs?.name ? 'text-black underline' : 'text-productTabsBg hover:underline'}`}>
                  {itemTabs?.shortName}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products
