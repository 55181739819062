import React, { useState } from 'react'
import axios from '../axios'

const Contact = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const sendEmail = async (e) => {
    e.preventDefault()
    console.log('launched')
    try {
      const emailResponse = await axios.post('/send-email', {
        firstName,
        lastName,
        email,
        message,
      })
      setEmail('')
      setFirstName('')
      setLastName('')
      setMessage('')

      console.log(emailResponse)
      // if ( emailResponse.status === true ) {
      //   setMailSent( true )
      // }
    } catch (err) {
      console.error('Error sending mail', err)
    }
  }

  return (
    <div
      className='fade-in w-viewWidth md:h-viewHeight pb-20 md:pb-0 sm:px-64 md:pt-52 pt-20 flex flex-col items-center md:items-start md:flex-row gap-16 md:gap-16 lg:gap-0 justify-between'
      style={{
        backgroundImage: 'url("./contactbg.jpg")',
        backgroundSize: 'cover',
      }}
      id='Contact us'
    >
      <div className='mt-10' style={{ fontFamily: 'garamond-medium' }}>
        <p className='text-contact sm:text-contactHead text-contactHeadMobile'>
          Contact us
        </p>
        <p className='text-contact sm:text-contactSub text-contactSubMobile mt-8'>
          Email or call us with your queries
        </p>
        <p className='text-contact sm:text-contactSub text-contactSubMobile mt-10'>
          Call: +1 (775)440-4331
        </p>
        <p className='text-contact sm:text-contactSub text-contactSubMobile mt-2'>
          Email: sales@millbundle.com
        </p>
      </div>
      <div
        className='bg-white rounded-md pb-6'
        style={{ fontFamily: 'garamond-medium' }}
      >
        <div className='sm:flex sm:flex-row flex-col justify-between py-6 px-6 gap-4'>
          <div>
            <p className='text-xl text-inputLabel'>First name*</p>
            <input
              type='text'
              className='border-2 px-4 py-5 mt-1 bg-inputBg border-gray-300 rounded-md focus:outline-none'
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </div>
          <div>
            <p className='text-xl text-inputLabel'>Last name</p>
            <input
              type='text'
              className='border-2 px-4 py-5 mt-1 bg-inputBg border-gray-300 rounded-md focus:outline-none'
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </div>
        </div>
        <div className='px-6'>
          <p className='text-xl text-inputLabel'>Email*</p>
          <input
            type='text'
            className='border-2 sm:w-full px-4 py-5 mt-1 bg-inputBg border-gray-300 rounded-md focus:outline-none'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className='px-6 mt-6'>
          <p className='text-xl text-inputLabel'>What can we help you with?</p>
          <textarea
            type='text'
            className='border-2 w-full h-28 px-4 py-5 mt-1 bg-inputBg border-gray-300 rounded-md focus:outline-none'
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        <div className='flex justify-center sm:justify-start'>
          <button
            className='bg-submitBg text-white rounded-lg px-10 py-4 text-xl font-bold ml-6 mt-6'
            onClick={(e) => sendEmail(e)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default Contact
